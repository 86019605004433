// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import * as d3 from "d3";
import "particles.js";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

window.addEventListener("turbolinks:load", () => {
  if (document.querySelector("#particles-js")) {
    particlesJS.load("particles-js", "particles.json", function () {
      console.log("callback - particles-js config loaded");
    });
  }
});
